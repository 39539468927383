<template>
  <div class="absolute h-full w-full">
    <Transition>
      <div v-if="desktopHeader && mobileMenu" class="fixed z-10 bg-blue w-full backdrop-blur-sm bg-opacity-30 p-[5%] shadow-md">
          <div class=" text-white w-100%">
            <div class="cursor-pointer flex flex-row text-[7vw]" @click="openDiscord">
              join discord
            </div>
            <div class="cursor-pointer flex flex-row text-[7vw]" @click="openWhitepaper" >
              whitepaper
            </div>
            <div class="cursor-pointer flex flex-row text-[7vw]" >
              connect wallet
            </div>
          </div>
      </div>
    </Transition>
    <div v-if="desktopHeader"
         class="fixed top-[20px] right-[20px] cursor-pointer z-10"
         @click="mobileMenu=!mobileMenu"
    >
      <svg width="64px" height="64px" viewBox="0 0 24.00 24.00" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
          <path d="M4 18L20 18" stroke="#FFFFFF" stroke-width="8" stroke-linecap="round"></path>
          <path d="M4 18L20 18" stroke="#0085FF" stroke-width="3" stroke-linecap="round"></path>
          <path d="M4 12L20 12" stroke="#FFFFFF" stroke-width="8" stroke-linecap="round"></path>
          <path d="M4 12L20 12" stroke="#0085FF" stroke-width="3" stroke-linecap="round"></path>
          <path d="M4 6L20 6" stroke="#FFFFFF" stroke-width="8" stroke-linecap="round"></path>
          <path d="M4 6L20 6" stroke="#0085FF" stroke-width="3" stroke-linecap="round"></path>
        </g>
      </svg>
    </div>
    <div class="absolute h-[93%] justify-between w-[80vw] flex flex-col left-[50%] translate-x-[-50%] top-[50%] translate-y-[-50%]">


      <homepage-header></homepage-header>
      <home-body></home-body>
      <homepage-footer></homepage-footer>
    </div>

  </div>
</template>

<script>
import HomepageHeader from "./Header"
import HomeBody from "./Body"
import HomepageFooter from "@/components/Footer";
export default {
  name: "HomePage",
  components: {HomepageFooter, HomepageHeader, HomeBody },
  data(){
    return {
      desktopHeader: window.innerWidth <= 767,
      mobileMenu:false
    }
  },
  created() {
    addEventListener("resize", () => {
      this.desktopHeader = innerWidth <= 767;
    });
  },
  methods:{
    openDiscord() {
      window.open("https://discord.gg/uxHqcVdKZV");
    },
    openTwitter() {
      window.open("https://twitter.com/intent/follow?screen_name=blockspingaming");
    },
    openInstagram() {
      window.open("https://www.instagram.com/blockspingaming/");
    },
    openWhitepaper() {
      window.open("https://blockspingaming.gitbook.io/spincity-tycoon-whitepaper/");
    },
  }
}
</script>

<style scoped>

.v-enter-active,
.v-leave-active {
  transition: all 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}



</style>